@use '@angular/material' as mat;
@import '@angular/material/theming';

@include mat.core();

// Typography
//$custom-typography: mat.define-typography-config(
//  $font-family: Raleway,
//  $headline: mat.define-typography-level(24px, 48px, 400),
//  $body-1: mat.define-typography-level(16px, 24px, 400)
//);
//@include mat.all-component-typographies($custom-typography);

$tts-palette: (
  50: #0aa2b3,
  100: #0aa2b3,
  200: #0aa2b3,
  300: #0aa2b3,
  400: #0aa2b3,
  500: #0aa2b3,
  600: #0aa2b3,
  700: #0aa2b3,
  800: #0aa2b3,
  900: #0aa2b3,
  A100: #0aa2b3,
  A200: #0aa2b3,
  A400: #0aa2b3,
  A700: #0aa2b3,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

// Define the primary, accent and warn palettes
$tts-primary-palette: mat.define-palette($tts-palette);
$tts-accent-palette: mat.define-palette($tts-palette);

//$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent);
$tts-theme: mat.define-light-theme($tts-primary-palette, $tts-accent-palette);

//@include mat.all-component-themes($my-app-theme);
@include mat.all-component-themes($tts-theme);

// Light theme
//$light-primary: mat.define-palette(mat.$grey-palette, 200, 500, 300);
//$light-accent: mat.define-palette(mat.$brown-palette, 200);
//$light-warn: mat.define-palette(mat.$deep-orange-palette, 200);
//
//$light-theme: mat.define-light-theme($light-primary, $light-accent, $light-warn);
//
//.light-theme {
//  @include mat.all-component-themes($light-theme)
//}

:root {
  --primary-10: rgba(10, 162, 179, 0.1);
  --primary-20: rgba(10, 162, 179, 0.2);
  --primary-30: rgba(10, 162, 179, 0.3);
  --primary-40: rgba(10, 162, 179, 0.4);
  --primary-50: rgba(10, 162, 179, 0.5);

  //--success-10: rgba(45, 211, 111, 0.1);
  //--success-20: rgba(45, 211, 111, 0.2);
  //
  //--warn-10: rgba(255, 196, 9, 0.1);
  //--warn-20: rgba(255, 196, 9, 0.2);
  //
  --danger-10: rgba(240, 78, 35, 0.1);
  --danger-20: rgba(240, 78, 35, 0.2);
}

:root {
  --ion-color-primary: #0aa2b3;
  --ion-color-primary-rgb: 10, 162, 179;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #098f9e;
  --ion-color-primary-tint: #23abbb;

  --ion-color-secondary: #09656c;
  --ion-color-secondary-rgb: 9, 101, 108;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #08595f;
  --ion-color-secondary-tint: #22747b;

  --ion-color-tertiary: #09656c;
  --ion-color-tertiary-rgb: 9, 101, 108;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #08595f;
  --ion-color-tertiary-tint: #22747b;

  --ion-color-success: #4eb857;
  --ion-color-success-rgb: 78, 184, 87;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #45a24d;
  --ion-color-success-tint: #60bf68;

  --ion-color-warning: #fbb040;
  --ion-color-warning-rgb: 251, 176, 64;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dd9b38;
  --ion-color-warning-tint: #fbb853;

  --ion-color-danger: #f04e23;
  --ion-color-danger-rgb: 240, 78, 35;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d3451f;
  --ion-color-danger-tint: #f26039;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #7fa9f0;
  --ion-color-light-rgb: 127, 169, 240;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #7095d3;
  --ion-color-light-tint: #8cb2f2;
}

/** Ionic CSS Variables **/
//:root {
//  /** primary **/
//  --ion-color-primary: #3880ff;
//  --ion-color-primary-rgb: 56, 128, 255;
//  --ion-color-primary-contrast: #ffffff;
//  --ion-color-primary-contrast-rgb: 255, 255, 255;
//  --ion-color-primary-shade: #3171e0;
//  --ion-color-primary-tint: #4c8dff;
//
//  /** secondary **/
//  --ion-color-secondary: #3dc2ff;
//  --ion-color-secondary-rgb: 61, 194, 255;
//  --ion-color-secondary-contrast: #ffffff;
//  --ion-color-secondary-contrast-rgb: 255, 255, 255;
//  --ion-color-secondary-shade: #36abe0;
//  --ion-color-secondary-tint: #50c8ff;
//
//  /** tertiary **/
//  --ion-color-tertiary: #5260ff;
//  --ion-color-tertiary-rgb: 82, 96, 255;
//  --ion-color-tertiary-contrast: #ffffff;
//  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//  --ion-color-tertiary-shade: #4854e0;
//  --ion-color-tertiary-tint: #6370ff;
//
//  /** success **/
//  --ion-color-success: #2dd36f;
//  --ion-color-success-rgb: 45, 211, 111;
//  --ion-color-success-contrast: #ffffff;
//  --ion-color-success-contrast-rgb: 255, 255, 255;
//  --ion-color-success-shade: #28ba62;
//  --ion-color-success-tint: #42d77d;
//
//  /** warning **/
//  --ion-color-warning: #ffc409;
//  --ion-color-warning-rgb: 255, 196, 9;
//  --ion-color-warning-contrast: #000000;
//  --ion-color-warning-contrast-rgb: 0, 0, 0;
//  --ion-color-warning-shade: #e0ac08;
//  --ion-color-warning-tint: #ffca22;
//
//  /** danger **/
//  --ion-color-danger: #eb445a;
//  --ion-color-danger-rgb: 235, 68, 90;
//  --ion-color-danger-contrast: #ffffff;
//  --ion-color-danger-contrast-rgb: 255, 255, 255;
//  --ion-color-danger-shade: #cf3c4f;
//  --ion-color-danger-tint: #ed576b;
//
//  /** dark **/
//  --ion-color-dark: #222428;
//  --ion-color-dark-rgb: 34, 36, 40;
//  --ion-color-dark-contrast: #ffffff;
//  --ion-color-dark-contrast-rgb: 255, 255, 255;
//  --ion-color-dark-shade: #1e2023;
//  --ion-color-dark-tint: #383a3e;
//
//  /** medium **/
//  --ion-color-medium: #92949c;
//  --ion-color-medium-rgb: 146, 148, 156;
//  --ion-color-medium-contrast: #ffffff;
//  --ion-color-medium-contrast-rgb: 255, 255, 255;
//  --ion-color-medium-shade: #808289;
//  --ion-color-medium-tint: #9d9fa6;
//
//  /** light **/
//  --ion-color-light: #f4f5f8;
//  --ion-color-light-rgb: 244, 245, 248;
//  --ion-color-light-contrast: #000000;
//  --ion-color-light-contrast-rgb: 0, 0, 0;
//  --ion-color-light-shade: #d7d8da;
//  --ion-color-light-tint: #f5f6f9;
//}

//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #428cff;
//    --ion-color-primary-rgb: 66,140,255;
//    --ion-color-primary-contrast: #ffffff;
//    --ion-color-primary-contrast-rgb: 255,255,255;
//    --ion-color-primary-shade: #3a7be0;
//    --ion-color-primary-tint: #5598ff;
//
//    --ion-color-secondary: #50c8ff;
//    --ion-color-secondary-rgb: 80,200,255;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255,255,255;
//    --ion-color-secondary-shade: #46b0e0;
//    --ion-color-secondary-tint: #62ceff;
//
//    --ion-color-tertiary: #6a64ff;
//    --ion-color-tertiary-rgb: 106,100,255;
//    --ion-color-tertiary-contrast: #ffffff;
//    --ion-color-tertiary-contrast-rgb: 255,255,255;
//    --ion-color-tertiary-shade: #5d58e0;
//    --ion-color-tertiary-tint: #7974ff;
//
//    --ion-color-success: #2fdf75;
//    --ion-color-success-rgb: 47,223,117;
//    --ion-color-success-contrast: #000000;
//    --ion-color-success-contrast-rgb: 0,0,0;
//    --ion-color-success-shade: #29c467;
//    --ion-color-success-tint: #44e283;
//
//    --ion-color-warning: #ffd534;
//    --ion-color-warning-rgb: 255,213,52;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e0bb2e;
//    --ion-color-warning-tint: #ffd948;
//
//    --ion-color-danger: #ff4961;
//    --ion-color-danger-rgb: 255,73,97;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #e04055;
//    --ion-color-danger-tint: #ff5b71;
//
//    --ion-color-dark: #f4f5f8;
//    --ion-color-dark-rgb: 244,245,248;
//    --ion-color-dark-contrast: #000000;
//    --ion-color-dark-contrast-rgb: 0,0,0;
//    --ion-color-dark-shade: #d7d8da;
//    --ion-color-dark-tint: #f5f6f9;
//
//    --ion-color-medium: #989aa2;
//    --ion-color-medium-rgb: 152,154,162;
//    --ion-color-medium-contrast: #000000;
//    --ion-color-medium-contrast-rgb: 0,0,0;
//    --ion-color-medium-shade: #86888f;
//    --ion-color-medium-tint: #a2a4ab;
//
//    --ion-color-light: #222428;
//    --ion-color-light-rgb: 34,36,40;
//    --ion-color-light-contrast: #ffffff;
//    --ion-color-light-contrast-rgb: 255,255,255;
//    --ion-color-light-shade: #1e2023;
//    --ion-color-light-tint: #383a3e;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #000000;
//    --ion-background-color-rgb: 0,0,0;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    --ion-item-background: #000000;
//
//    --ion-card-background: #1c1c1d;
//  }
//
//  .ios ion-modal {
//    --ion-background-color: var(--ion-color-step-100);
//    --ion-toolbar-background: var(--ion-color-step-150);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #121212;
//    --ion-background-color-rgb: 18,18,18;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-border-color: #222222;
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    --ion-item-background: #1e1e1e;
//
//    --ion-toolbar-background: #1f1f1f;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}

:root {
  --heading-color: #1c1b1b;
  --text-color: #1c1b1b;
  --text-color-rgb: 28, 27, 27;
  --text-color-light: #6a6a6a;
  --text-color-light-rgb: 106, 106, 106;
  --link-color: #6a6a6a;
  --link-color-rgb: 106, 106, 106;
  --border-color: #cfcfcf;
  --border-color-rgb: 207, 207, 207;
  --button-background: #0aa2b3;
  --button-background-rgb: 10, 162, 179;
  --button-text-color: #ffffff;
  --header-background: #ffffff;
  --header-heading-color: #1c1b1b;
  --header-light-text-color: #6a6a6a;
  --header-border-color: #dddddd;
  --footer-background: #ffffff;
  --footer-text-color: #6a6a6a;
  --footer-heading-color: #1c1b1b;
  --footer-border-color: #e9e9e9;
  --navigation-background: #1c1b1b;
  --navigation-background-rgb: 28, 27, 27;
  --navigation-text-color: #ffffff;
  --navigation-text-color-light: rgba(255, 255, 255, 0.5);
}
