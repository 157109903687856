/* Works - pass "my-custom-class" in cssClass to increase specificity */
ion-modal.confirm-modal-class {
  --background: white !important;
  --height: 280px !important;
  --width: min(90%, 460px) !important;
  --box-shadow: 0 !important;
  --border-radius: 0.5rem !important;
}

ion-modal.search-parent-class {
  @include media-breakpoint('gt-xs') {
    --width: min(90%, 700px) !important;
    --height: min(90vh, 780px) !important;
    --border-radius: 0.5em;
  }
}

ion-modal.add-manual-wtb {
  @include media-breakpoint('gt-xs') {
    --width: min(90%, 800px) !important;
    --height: min(90vh, 620px) !important;
    --border-radius: 0.5rem !important;
  }
}

ion-modal.address-picker {
  // @include media-breakpoint('gt-xs') {
  //   --height: 500px;
  //   --width: 500px;
  //   --border-radius: .5rem !important;
  //   // position: relative !important;
  // }
}

.mat-standard-chip:focus::after {
  opacity: 0 !important;
}

ion-modal.create-update-class {
  @include media-breakpoint('gt-xs') {
    --height: 650px;
    --width: 500px;
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.add-role-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 150px) !important;
    --width: min(90%, 500px) !important;
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.create-update-contact {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 450px) !important;
    --width: min(90%, 500px) !important;
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.create-update-bank-account {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 520px) !important;
    --width: min(90%, 500px) !important;
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.create-update-document {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 555px) !important;
    --width: min(90%, 500px) !important;
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.create-update-partner {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 900px) !important;
    --width: min(90%, 550px) !important;
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.view-document {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 800px) !important;
    --width: min(90%, 1200px) !important;
    --border-radius: 0.5rem !important;
    //--border-radius: .5rem !important;
    // position: relative !important;
  }
}

ion-modal.create-update-location {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 525px) !important;
    --width: min(90%, 500px) !important;
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.settings-list-edit-modal {
  // resize: both !important;

  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 600px);
    --width: min(80%, 550px);
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.self-update-email {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 290px);
    --width: min(80%, 550px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.self-update-profile {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 370px);
    --width: min(80%, 550px);
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.update-invoice-setting {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 750px);
    --width: min(80%, 600px);
    --border-radius: 0.5rem !important;
    // position: relative !important;
  }
}

ion-modal.create-package-doc {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 370px);
    --width: min(80%, 600px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.create-damage-event {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 540px);
    --width: min(80%, 600px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.create-update-user {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 623px);
    --width: min(80%, 600px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.save-address-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 455px);
    --width: min(80%, 600px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.create-document-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 820px);
    --width: min(80%, 1248px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.add-waybill-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 800px);
    --width: min(80%, 1200px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.add-license-plate {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 360px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.change-password-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 370px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.invite-partner-to-mercurius {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 670px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.create-update-truck-type {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 400px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.edit-wtb-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 400px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.select-product-variants-modal {
  @include media-breakpoint('gt-xs') {
    --max-width: min(80%, 600px);
  }
}

ion-modal.usno-setting-modal {
  @include media-breakpoint('gt-xs') {
    --max-width: min(80%, 430px);
  }
}

ion-modal.cancel-order-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 540px);
    --width: min(80%, 600px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.update-order-note-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 250px);
    --width: min(80%, 600px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.add-role-to-user-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 300px);
    --width: min(80%, 480px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.add-shipping-modal {
  @include media-breakpoint('gt-xs') {
    --max-width: min(80%, 480px) !important;
  }
}

ion-modal.add-discount-modal {
  @include media-breakpoint('gt-xs') {
    --max-width: min(80%, 480px);
  }
}

ion-modal.edit-contact-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 270px);
    --width: min(80%, 480px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.change-customer-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 270px);
    --width: min(80%, 480px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.view-requests {
  @include media-breakpoint('gt-xs') {
    --width: min(80%, 1200px) !important;
  }
}

ion-modal.register-seller-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 820px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.manage-addresses-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 450px);
    --width: min(80%, 800px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.update-order-tags-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 300px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
  }
}

ion-modal.edit-shipping-modal {
  @include media-breakpoint('gt-xs') {
    --height: min(90vh, 615px);
    --width: min(80%, 650px);
    --border-radius: 0.5rem !important;
    --backdrop-opacity: 0.5 !important;
  }
}

.icon-picker-popover .popover-content {
  --border-radius: 0.5rem !important;
  --height: 330px !important;
  --width: 460px !important;
}

ion-modal.auto-height {
  --height: 100%;

  .ion-page {
    position: relative;
    display: block;
    contain: content;
  }

  .inner-content {
    overflow: auto;
    padding: 10px;
  }

  @include media-breakpoint('gt-xs') {
    --width: min(80%, 650px);
    --height: auto;
    --border-radius: 0.5rem !important;

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }

    .inner-content {
      overflow: auto;
      padding: 1rem;
    }
  }
}
