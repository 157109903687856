@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'src/app/shared/scss/core';
//@import "src/shared/scss/ionic-override";
@import 'src/app/shared/scss/modal-wrappers';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

//noinspection CssUnknownTarget
//@import 'swiper/scss';
////noinspection CssUnknownTarget
//@import 'swiper/scss/navigation';
////noinspection CssUnknownTarget
//@import 'swiper/scss/pagination';

//@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

body {
  background: white !important;
  min-width: 320px !important;
  font-family: 'Montserrat', sans-serif !important;
  color: var(--text-color);
  font-size: 16px;

  width: 100% !important;
  height: 100% !important;
  //display: grid !important;
}

*:not(mat-icon, img, ion-img, ion-icon, mat-icon > span) {
  font-family: 'Montserrat', sans-serif !important;
}

//ion-content.page {
//  max-width: 1200px;
//  margin: 0 auto;
//  align-self: center;
//  --padding-top: 1rem;
//  --padding-bottom: 1rem;
//  --padding-start: 1rem;
//  --padding-end: 1rem;
//}

:host ::ng-deep {
  .mat-form-field-outline {
    background: #f6f8fb !important;
    border-radius: 8px;
  }
}

.mat-form-field-outline {
  background: rgba(10, 162, 179, 0.035) !important;
  border-radius: 8px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #f6f8fb inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #f6f8fb inset; /*your box-shadow*/
  -webkit-text-fill-color: #333;
}

.invisible-tabs {
  > .mat-tab-header {
    display: none;
  }
}

.font-primary {
  color: var(--ion-color-primary);
}

.heading {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.65;
  list-style: none;
  text-align: center;
  white-space: normal;
  visibility: visible !important;
  font-size: inherit;
  font-style: var(--heading-font-style);
  letter-spacing: 0.2em;
  text-decoration: none;
}

.custom-snackbar {
  padding: 0 !important;
  min-height: 0 !important;
  max-width: 700px !important;
  background: transparent;
  box-shadow: none;
}

.card {
  // style an outlined card
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 16px;
  // margin-bottom: 16px;
}

::selection {
  background: var(--button-background);
  color: white;
}

.back-button {
  width: fit-content;
  position: absolute;
  top: 0;
  left: 0.75rem;
  background: white;
  border-radius: 50%;
}

.primary-font {
  color: var(--ion-color-primary);
}

.primary-bg {
  background: var(--ion-color-primary);
  color: white;
}
.purple-link {
  text-decoration-line: underline !important;
  color: #736ab4 !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.purple {
  color: #736ab4;
}

//.primary-bg-opacity {
//  background-color: rgba(#0aa2b3, 0.1) !important;
//  //color: white;
//}

.spacer {
  flex: 1 1 auto;
}

.tts-theme-highlighted {
  background: var(--ion-color-primary);
  color: white;
  width: fit-content;
  padding: 0.5em;
  text-transform: uppercase;
}

ion-card {
  margin: 0;
}

.my-row {
  background: white !important;

  td.mat-cell {
    padding: 0.25rem;
    border: none;
    //border-top: 4px solid #f9b282;
  }
}

:host ::ng-deep {
  th.mat-header-cell {
    border: none !important;
    padding: 0 0.25rem !important;

    &.mat-column-select {
      padding-right: 0.25rem !important;
    }
  }

  .mat-cell {
    border: none !important;
  }

  .mat-header-cell {
    border: none !important;
  }
}

.mat-cell {
  border: none !important;
}

.mat-header-cell {
  border: none !important;
}

.mat-paginator-icon {
  margin: 0 auto !important;
}

tr.mat-row,
tr.mat-footer-row {
  background: white;

  // First and last mat cell should be rounded
  .mat-cell:first-child,
  .mat-cell:last-child {
    border-radius: 0.5rem !important;
  }

  .mat-cell:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .mat-cell:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.mat-paginator-container {
  .mat-form-field.mat-form-field-type-mat-select .mat-form-field-infix {
    padding-bottom: 1.25rem !important;
  }
}
